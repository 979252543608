import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import exercitii from "./exercitii";
const CopyClipboard = () => {
  const { lab, ex } = useParams();
  const tRef = useRef();

  useEffect(() => {
    tRef.current.select();
  });
  return (
    <>
      <textarea ref={tRef} value={exercitii[lab][ex]} />
    </>
  );
};

export default CopyClipboard;

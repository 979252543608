var exercitii = Array(13)
  .fill()
  .map(() => Array(13).fill());

exercitii[7][1] =
  "clear variables;\n" +
  "n = 0:20;\n" +
  "x1 = 1.5*cos(2*pi*0.1*n + pi/4);\n" +
  "x2 = abs(10 - n);\n" +
  "y = conv(x1, x2);\n" +
  "figure,\n" +
  "subplot(311), stem(n, x1), grid,\n" +
  "subplot(312), stem(n, x2), grid,\n" +
  "subplot(313), stem(0:length(y) - 1, y), grid,\n" +
  "length(y)";

exercitii[7][2] =
  "clear variables;\n" +
  "n = 0:31;\n" +
  "h = exp(-0.1*n);\n" +
  "n1 = 0:40;\n" +
  "x = 1.*(n1>=0) - 1.*(n1>=20);\n" +
  "y = conv(x, h);\n" +
  "figure,\n" +
  "subplot(311), stem(n, h), grid,\n" +
  "subplot(312), stem(n1, x), grid,\n" +
  "subplot(313), stem(0:length(y) - 1, y), grid,";

exercitii[7][3] =
  "clear variables;\n" +
  "n = 0:31;\n" +
  "h = exp(-0.15*n);\n" +
  "n1 = 0:40;\n" +
  "x = 1.*(n1>=0) - 1.*(n1>= 30);\n" +
  "y = conv(x, h);\n" +
  "figure,\n" +
  "subplot(311), stem(n, h), grid,\n" +
  "subplot(312), stem(n1, x), grid,\n" +
  "subplot(313), stem(0:length(y) - 1, y), grid,";

exercitii[7][4] =
  "clear variables;\n" +
  "num = 1;\n" +
  "den = [1 -0.5];\n" +
  "h = impz(num, den, 30);\n" +
  "n1 = -10:30;\n" +
  "y1 = 1.*(n1>=0);\n" +
  "\n" +
  "figure,\n" +
  "stem(n1, y1);\n" +
  "     y2 = conv(y1, h);\n" +
  "n2 = 0:39;\n" +
  "figure, stem(n2, n2);\n" +
  "    y3 = conv(n2, h);\n" +
  "n3 = 0:199;\n" +
  "x = 10*cos((pi*n3)/3).*(n3>=0);\n" +
  "figure, stem(n3, x);\n" +
  "\n" +
  "y4 = conv(x, h);\n" +
  "n4 = 0:49;\n" +
  "x1 = 10*power(0.5, n4).*(n4>=0);\n" +
  "figure, stem(n4, x1);\n" +
  "\n" +
  "y5 = conv(x1, h);";

exercitii[7][5] =
  "clear variables;\n" +
  "num = [1 -1];\n" +
  "den1 = [1 -0.25];\n" +
  "den2 = [1 -0.5];\n" +
  "den = conv(den1, den2);\n" +
  "h = impz(num, den, 100);\n" +
  "n = 0:99;\n" +
  "u1 = 1.*(n>=0);\n" +
  "y = conv(h, u1);\n" +
  "figure, stem(0:length(y) - 1, y);\n" +
  "\n" +
  "num11 = [1 0];\n" +
  "den111 = [1 -0.25];\n" +
  "den221 = [1 -0.5];\n" +
  "den11 = conv(den111, den221);\n" +
  "h1 = impz(num11, den11, 100);\n" +
  "y1 = conv(h1, u1);\n" +
  "num22 = [0 -1];\n" +
  "den221 = [1 -0.25];\n" +
  "den222 = [1 -0.5];\n" +
  "den22 = conv(den221, den222);\n" +
  "h2 = impz(num22, den22, 100);\n" +
  "y2 = conv(h2, u1);\n" +
  "ysuma = y1 + y2;\n" +
  "figure,\n" +
  "subplot(311), stem(0:length(y1) - 1, y1);\n" +
  "subplot(312), stem(0:length(y2) - 1, y2);\n" +
  "subplot(313), stem(0:length(ysuma) - 1, ysuma);";

exercitii[7][6] =
  " clear variables;\n" +
  "h1 = [2 3 2 1 -0.5 1 2 4];\n" +
  "h2 = [3 -1 5 0 2 6 0 0];\n" +
  "n = 0:30;\n" +
  "x = cos(2*pi*1/10*n + pi/4);\n" +
  "y = conv(x, h1);\n" +
  "figure, stem(0:length(y) - 1, y);\n" +
  " \n" +
  "h = h1 + h2;\n" +
  "y1 = conv(x, h);\n" +
  "figure, stem(0:length(y1) - 1, y1);\n" +
  " \n" +
  "hnou = h2 + h1;\n" +
  "y2 = conv(x, hnou);\n" +
  "figure, stem(0:length(y2) - 1, y2);";

exercitii[7][7] =
  "clear variables;\n" +
  "n=0:10;\n" +
  "x1=1.1*cos(pi*0.25*n+pi/6);\n" +
  "x2=(-2).^n;\n" +
  "figure(1);\n" +
  "subplot(211);\n" +
  "stem(n,x1);grid;\n" +
  "title('Prima secventa');\n" +
  "subplot(212);\n" +
  "stem(n,x2);grid;\n" +
  "title('A doua seventa');\n" +
  "figure(2);\n" +
  "y=cconv(x1,x2,11);\n" +
  "disp(y);\n" +
  "stem(0:length(y)-1, y);grid;\n" +
  " title('Convolutia circulara');";
exercitii[7][8] =
  "clear variables;\n" +
  "n = 0:15;\n" +
  "x1 = 1.1*sin(2*pi*0.05*n + pi/4);\n" +
  "x2 = power(-1, n);\n" +
  "y1 = conv(x1, x2);\n" +
  "y2 = cconv(x1, x2, 16);\n" +
  "X1 = fft(x1, 16);\n" +
  "X2 = fft(x2, 16);\n" +
  "Y = X1.*X2;\n" +
  "y3 = ifft(Y);\n" +
  "y4 = cconv(x1, x2, 31);\n" +
  "figure,\n" +
  "subplot(321), stem(n, x1);\n" +
  "subplot(322), stem(n, x2);\n" +
  "subplot(323), stem(0:length(y1) - 1, y1);\n" +
  "subplot(324), stem(0:length(y2) - 1, y2);\n" +
  "subplot(325), stem(0:length(y3) - 1, y3);\n" +
  "subplot(326), stem(0:length(y4) - 1, y4);";
exercitii[7][9] =
  "clear variables;\n" +
  "n1 = 0:30;\n" +
  "x1 = 1.*(n1>=0) - 1.*(n1>=25);\n" +
  "n2 = 0:20;\n" +
  "x2 = power(-7, n2);\n" +
  "y1 = conv(x1, x2);\n" +
  "X1 = fft(x1, length(y1));\n" +
  "X2 = fft(x2, length(y1));\n" +
  "Y = X1.*X2;\n" +
  "y2 = ifft(Y);\n" +
  "figure,\n" +
  "subplot(411), stem(n1, x1);\n" +
  "subplot(412), stem(n2, x2);\n" +
  "subplot(413), stem(0:length(y1) - 1, y1);\n" +
  "subplot(414), stem(0:length(y2) - 1, y2);";
exercitii[7][10] =
  "clear variables;\n" +
  "x1 = [3 4.2 11 0 7 -1 0 2];\n" +
  "x2 = [1.2 3 0 -0.5 2 0 0 0];\n" +
  "y1 = conv(x1, x2)\n" +
  "length(y1)\n" +
  "X1 = fft(x1, 8);\n" +
  "X2 = fft(x2, 8);\n" +
  "Y = X1.*X2;\n" +
  "y2 = ifft(Y)\n" +
  "x11 = [3 4.2 11 0 7 -1 0 2 zeros(1, 7)];\n" +
  "x22 = [1.2 3 0 -0.5 2 zeros(1, 10)];\n" +
  "X3 = fft(x11, length(y1));\n" +
  "X4 = fft(x22, length(y1));\n" +
  "Y1 = X3.*X4;\n" +
  "y3 = ifft(Y1)\n" +
  "x111 = [3 4.2 11 0 7 -1 0 2 zeros(1, 12)];\n" +
  "x222 = [1.2 3 0 -0.5 2 zeros(1, 15)];\n" +
  "X5 = fft(x111, length(y1) + 5);\n" +
  "X6 = fft(x222, length(y1) + 5);\n" +
  "Y2 = X5.*X6;\n" +
  "y4 = ifft(Y2)";

exercitii[8][1] =
  "clear variables;\n" +
  "N=21\n" +
  "fc=0.21\n" +
  "L=N+1\n" +
  "h=fir1(N,fc,boxcar(L))\n" +
  "figure,\n" +
  "impz(h)\n" +
  "figure,\n" +
  "zplane(h,1)\n" +
  "figure,\n" +
  "freqz(h,1,2^15)\n" +
  "[H,w]=freqz(h,1,2^15)\n" +
  "[tg,wtg]=grpdelay(h,1,w)\n" +
  "figure,\n" +
  "plot(wtg,tg);grid\n" +
  "%banda mai mare schimbam fereastra\n" +
  "h2=fir1(N,fc,blackman(L))\n" +
  "figure,\n" +
  "impz(h2)";
exercitii[8][2] =
  "clear variables;\n" +
  "L = 21;\n" +
  "N = L - 1;\n" +
  "Fl = 10*10^3;\n" +
  "Fu = 15*10^3;\n" +
  "Fs = 90*10^3;\n" +
  "wn1 = Fl/(Fs/2);\n" +
  "wn2 = Fu/(Fs/2);\n" +
  "h = fir1(N, [wn1 wn2], triang(L));\n" +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, zplane(h, 1);\n" +
  "figure, freqz(h, 1, 2^10);\n" +
  "h1 = fir1(N - 1, [wn1 wn2], boxcar(N));\n" +
  "figure, stem(0:length(h1) - 1, h1);\n" +
  "figure, zplane(h1, 1);\n" +
  "figure, freqz(h1, 1, 2^10);\n" +
  "h2 = fir1(N, [wn1 wn2], blackman(N + 1));\n" +
  "figure, stem(0:length(h2) - 1, h2);\n" +
  "figure, zplane(h2, 1);\n" +
  "figure, freqz(h2, 1, 2^10);";
exercitii[8][3] =
  "clear variables;\n" +
  "% definim parametrii\n" +
  "F_s = 5; % frecventade esantioanare\n" +
  "F_t = 0.21; % frecventa limita inferioara\n" +
  "N=51;\n" +
  "L=N+1;\n" +
  "w0 = 2*F_t/F_s;\n" +
  "dp = 0.2;\n" +
  "ds = 0.1;\n" +
  "% ordin, frecventa de taiere, amplitudinea corespunzatoare\n" +
  "frecventei de\n" +
  "% taiere, ponderile pentru FTJ\n" +
  "% proiectarea FIR echiriplu cu Remez\n" +
  "b = fircls1(51, w0, dp, ds);\n" +
  "disp('Coeficientii filtrului FIR'); disp(b');\n" +
  "[H, w] = freqz(b, 1, 512);\n" +
  "figure(1); subplot(211); plot(w/pi/2, 20*log10(abs(H)/max(abs(H))),\n" +
  "'LineWidth', 1.5); grid;\n" +
  "ylabel('|H(omega)| [dB]');\n" +
  "%axis([0 0.5 -60 2]);\n" +
  "title('FIR echiliblu de faza liniara Caracteristica');\n" +
  "subplot(212); plot(w/pi/2, 180/pi*(angle(H)), 'LineWidth', 1.5);\n" +
  "grid;\n" +
  "xlabel('f=omega/(2*pi)'); ylabel('<H(omega) [grade]');\n" +
  "title('Freecventa normalizata');\n" +
  "axis([0 0.5 -180 180]);";
exercitii[8][4] =
  "clear variables;\n" +
  "% definim parametrii\n" +
  "N = 40; % ordin\n" +
  "n = 0:N-1;\n" +
  "Fs1=0.2;\n" +
  "Fs2=0.6;\n" +
  "Fs=5.5;\n" +
  "w1 = 2*Fs1/Fs; % frecventa de taiere inferioara\n" +
  "w2 = 2*Fs2/Fs; % frecventa de taiere superioara\n" +
  "% FTB , FIR, fereastra Blackman (lungimea ferestrei=ordin filtru+1)\n" +
  "a = fir1(N-1, [w1 w2], boxcar(N));\n" +
  "% evaluam raspunsul in frecventa\n" +
  "[Ha, w] = freqz(a, 1, 1024);\n" +
  "x = abs(Ha)/max(abs(Ha));\n" +
  "%pentru rectangular\n" +
  "figure(1); subplot(221);plot(w/(pi), x, 'LineWidth', 2.5); grid;\n" +
  "title('Proiectare cu fereastra rectangulara');\n" +
  "a1 = fir1(N-1, [w1 w2], hamming(N));\n" +
  " %Haamming face netezire\n" +
  "% evaluam raspunsul in frecventa\n" +
  "[Ha1, wa] = freqz(a1, 1, 1024);\n" +
  "x = abs(Ha1)/max(abs(Ha1));\n" +
  "%pentru rectangular\n" +
  "figure(1); subplot(222);plot(wa/(pi), x, 'LineWidth', 2.5); grid;\n" +
  "title('Proiectare cu fereastra rectangulara');";
exercitii[8][5] =
  "clear variables;\n" +
  "L = 57;\n" +
  "wn1 = 0.18;\n" +
  "wn2 = 0.48;\n" +
  'h = fir1(L - 1, [wn1 wn2], "stop", hann(L));\n' +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, freqz(h, 1, 2^10);";
exercitii[8][6] =
  "clear variables;\n" +
  "N=36\n" +
  "Fc=15*10^3\n" +
  "Fs=50*10^3\n" +
  "wn=Fc/(Fs/2)\n" +
  "%FTJ\n" +
  "f=[0 wn wn 1]\n" +
  "m=[1 1 0 1]\n" +
  "h=fir2(N,f,m)\n" +
  "figure,\n" +
  "freqz(h,1,2^15)\n" +
  "figure,\n" +
  "zplane(h,1)\n" +
  "figure,\n" +
  "impz(h)";
exercitii[8][7] =
  "clear variables;\n" +
  "L = 55;\n" +
  "N = L - 1;\n" +
  "m = [0 0 1 1];\n" +
  "f = [0 0.2 0.3 1];\n" +
  "h = fir2(N, f, m);\n" +
  "\n" +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, freqz(h, 1, 2^10);";
exercitii[8][8] =
  "clear variables;\n" +
  "A = [0 1 0];\n" +
  "fre = [0.2 0.25 0.45 0.5];\n" +
  "dev = [1 1 1]/100;\n" +
  "[n,f0,a0,w] = firpmord (fre,A,dev); %Determinare parametrii pentru filtru de ordin minim\n" +
  "h = firpm(n,f0,a0,w);\n" +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, freqz(h, 1, 2^10);\n" +
  "figure, grpdelay(h, 1, 2^10);";
exercitii[8][9] =
  "clear variables;\n" +
  "L = 61;\n" +
  "N = L - 1;\n" +
  "f = [0 0.1 0.9 1];\n" +
  "m = [0 1 1 0];\n" +
  "h = firpm(N, f, m, hilbert);\n" +
  "figure, freqz(h, 1, 2^10);";
exercitii[8][11] =
  "clear variables;\n" +
  "N = 55;\n" +
  "m = [0 0 1 1 0 0];\n" +
  "f = [0 0.2 0.22 0.43 0.5 1];\n" +
  "h = firpm(N, f, m);\n" +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, freqz(h, 1, 2^10);\n" +
  " ";
exercitii[8][12] =
  "clear variables;\n" +
  "Fl1 = 10*10^3;\n" +
  "Fu1 = 62*10^3;\n" +
  "Fl2 = 12*10^3;\n" +
  "Fu2 = 60*10^3;\n" +
  "Fs = 130*10^3;\n" +
  "fl1 = Fl1/Fs;\n" +
  "fu1 = Fu1/Fs;\n" +
  "fl2 = Fl2/Fs;\n" +
  "fu2 = Fu2/Fs;\n" +
  "fre = [fl1 fl2 fu2 fu1];\n" +
  "A = [0 1 0];\n" +
  "Astop = 10^-(40/20);\n" +
  "Apass = (10^(3/20)-1)/(10^(3/20)+1);\n" +
  "dev = [Astop Apass Astop];\n" +
  "[n,f0,a0,w0] = firpmord (fre,A,dev);\n" +
  "h = firpm(n,f0,a0,w0);\n" +
  "figure, stem(0:length(h) - 1, h);\n" +
  "figure, freqz(h, 1, 2^10);";
exercitii[9][1] =
  "clear variables;\n" +
  "w = pi/3;\n" +
  "z1 = exp(1i*w);\n" +
  "z2 = exp(-1i*w);\n" +
  "r1 = 0.6;\n" +
  "r2 = 0.96;\n" +
  "p1 = r1*z1; p2 = r1*z2;\n" +
  "p3 = r2*z1; p4 = r2*z2;\n" +
  "z = [z1 z2];\n" +
  "p11 = [p1 p2];\n" +
  "p22 = [p3 p4];\n" +
  "x = poly(z);\n" +
  "y = poly(p11);\n" +
  "[H, w] = freqz(x, y);\n" +
  "figure,\n" +
  "subplot(211), plot(w/pi, abs(H)), title('Modul'),\n" +
  "subplot(212), plot(w/pi, rad2deg(angle(H))), title('Faza'),\n" +
  " \n" +
  "y = poly(p22);\n" +
  "[H, w] = freqz(x, y);\n" +
  "figure,\n" +
  "subplot(211), plot(w/pi, abs(H)), title('Modul'),\n" +
  "subplot(212), plot(w/pi, rad2deg(angle(H))), title('Faza'),";
exercitii[9][2] =
  "figure, freqz(num, den, 2^10);\n" +
  "clear variables;\n" +
  "wc = 0.3*pi;\n" +
  "a = (1-sin(wc))/cos(wc);\n" +
  "num = (1-a)/2*[1 1];\n" +
  "den = [1 -a];\n" +
  "H = filt(num, den)\n" +
  "figure, grpdelay(num, den, 2^10);";
exercitii[9][3] =
  "clear variables;\n" +
  "M = 10;\n" +
  "h = (1/M)*ones(1, M);\n" +
  "[H, w] = freqz(h, 1, 2^10);\n" +
  "figure,\n" +
  "subplot(311), impz(h, 1);\n" +
  "subplot(312), plot(w/pi, abs(H));\n" +
  "subplot(313), plot(w/pi, rad2deg(angle(H)));\n" +
  " \n" +
  "n = 0:99;\n" +
  "f = 1/10;\n" +
  "s = cos(2*pi*f*n);\n" +
  "y = conv(s, h);\n" +
  "figure, plot(0:length(y) - 1, y);\n" +
  "zg = rand(1, length(n));\n" +
  "sem = s + zg;\n" +
  "y1 = conv(sem, h);\n" +
  "figure, plot(0:length(y1) - 1, y1);";
exercitii[9][4] =
  "clear variabels;\n" +
  "n=0:100;\n" +
  "num=[1 0];\n" +
  "den=[1 -0.9];\n" +
  "%raspunsul la impuls\n" +
  "H=impz(num,den,n);\n" +
  "figure,stem(n,H);title('Impuls H2(z)');\n" +
  "%raspunul in frecventa\n" +
  "figure,freqz(num,den,2^10);\n" +
  "%timp de intarziere de grup\n" +
  "figure,grpdelay(num,den,2^10);\n" +
  "%stabilitate\n" +
  "figure,zplane(num,den);\n" +
  "%Sistem stabil, toti polii sunt inauntru\n" +
  "if(isstable(num,den)==1)\n" +
  "disp('Sistem stabil')\n" +
  "else\n" +
  "disp('Sistemul este instabil')\n" +
  "end\n" +
  "%Excitatie\n" +
  "x=sin(2*pi*0.5*n);\n" +
  "y=conv(x,H);\n" +
  "figure,stem(0:length(y)-1,y);";
exercitii[9][5] =
  "clear variables;\n" +
  "n = 0:599;\n" +
  "T1 = 0.1;\n" +
  "num1 = T1/2*[1 1];\n" +
  "den1 = [1 -1];\n" +
  "x1 = 0.9.^(n*T1).*sin(2*n*T1);\n" +
  "h1 = impz(num1, den1);\n" +
  "y1 = conv(h1, x1);\n" +
  "T2 = 0.05;\n" +
  "num2 = T2/2*[1 1];\n" +
  "den2 = [1 -1];\n" +
  "x2 = 0.9.^(n*T2).*sin(2*n*T2);\n" +
  "h2 = impz(num2, den2);\n" +
  "y2 = conv(h2, x2);\n" +
  "figure,\n" +
  "subplot(411), stem(h1),\n" +
  "subplot(412), stem(y1),\n" +
  "subplot(413), stem(h2),\n" +
  "subplot(414), stem(y2),";
exercitii[9][6] =
  "clear variables;\n" +
  "N = 10;\n" +
  "num = 1;\n" +
  "den = [1 zeros(1, N - 1) -1];\n" +
  "figure, impz(num, den, 100),\n" +
  "figure, freqz(num, den),\n" +
  " \n" +
  "figure, zplane(num, den),\n" +
  " \n" +
  "n1 = 0:9;\n" +
  "x = 9 - n1;\n" +
  "x1 = [x zeros(1, 91)];\n" +
  "h = impz(num, den, 100);\n" +
  "y = conv(x1, h);\n" +
  "figure, stem(0:length(y) - 1, y),";
exercitii[10][1] =
  "clear variabels;\n" +
  "%met invariantei rasp la impuls\n" +
  "%FTB digital, Butterworth\n" +
  "%Atenuare mai mica de 1db la 4kHz si 6kHz\n" +
  "Rp=1; Fp=[4000 6000]; Wp=2*pi*Fp;\n" +
  "%Atenuarea mai mare e 40db la 3kHz si 8Kz\n" +
  "Rs=40; Fs =[3000 8000]; Ws=2*pi*Fs;\n" +
  "%Frecventa de esantionare 20kHz\n" +
  "Fsamp=20*10^3;\n" +
  "%proiectare filtru analogic\n" +
  "%nu se da ordinul => estimez\n" +
  "[n, Wn] = buttord(Wp, Ws, Rp, Rs, 's'); %ord6\n" +
  "[b, a] = butter(n, Wn, 's'); %ord, vect de frecv\n" +
  "[bzi, azi] = impinvar(b, a, Fsamp);\n" +
  "[Ha,wa]=freqs(b,a,2^10);\n" +
  "[Hzi,wzi]=freqz(bzi,azi,2^10);\n" +
  "%reprez grafica\n" +
  "figure,\n" +
  "subplot(211);\n" +
  "plot(wa/Fsamp/(2*pi),mag2db(abs(Ha)),'--'); grid;hold all;\n" +
  "plot(wzi/(2*pi),mag2db(abs(Hzi)));grid;\n" +
  "axis([0 0.8 -180 5]);\n" +
  "subplot(212);\n" +
  "plot(wa/Fsamp/(2*pi),rad2deg(angle(Ha)),'--'); grid;hold\n" +
  "all;\n" +
  "plot(wzi/(2*pi),rad2deg(angle(Hzi)));grid;\n" +
  "axis([0 0.8 -180 5]);\n" +
  "%b) rasp la impuls\n" +
  "figure,\n" +
  "subplot(211);impulse(b,a);\n" +
  "subplot(212);impz(bzi,azi);\n" +
  "%zerouri\n" +
  "figure,\n" +
  "subplot(211);pzmap(b,a);\n" +
  "subplot(212);zplane(bzi,azi);\n" +
  "%da, stabil\n" +
  "%fa: toti polii sunt in semipl stang al pl s\n" +
  "%fd: toti polii sunt in int cercului unitate\n" +
  "% bilinear\n" +
  "Wp_b = 2*Fsamp*tan(Wp/Fsamp/2);\n" +
  "Ws_b = 2*Fsamp*tan(Ws/Fsamp/2);\n" +
  "[n_b, Wn_b] = buttord(Wp_b, Ws_b, Rp, Rs, 's');\n" +
  "[b_b, a_b] = butter(n_b, Wn_b, 's');\n" +
  "[bzb, azb] = bilinear(b_b, a_b, Fsamp);\n" +
  "[Ha_b, wa_b] = freqs(b_b, a_b, 2^10);\n" +
  "[Hzb, wzb] = freqz(bzb, azb, 2^10);\n" +
  "figure,\n" +
  "subplot(2,1,1);\n" +
  "plot(wa_b/Fsamp/(2*pi), mag2db(abs(Ha_b)), '--'); grid on;\n" +
  "hold all;\n" +
  "plot(wzb/(2*pi), mag2db(abs(Hzb))); grid on;\n" +
  "axis([0 0.8 -180 5]);\n" +
  "subplot(2,1,2);\n" +
  "plot(wa_b/Fsamp/(2*pi), rad2deg(angle(Ha_b)), '--'); grid\n" +
  "on; hold all;\n" +
  "plot(wzb/(2*pi), rad2deg(angle(Hzb))); grid on;\n" +
  "figure,\n" +
  "subplot(2,1,1); impulse(b_b, a_b);\n" +
  "subplot(2,1,2); impz(bzb, azb);\n" +
  "figure,\n" +
  "subplot(2,1,1); pzmap(b_b, a_b);\n" +
  "subplot(2,1,2); zplane(bzb, azb);";
exercitii[10][2] =
  "clear variables;\n" +
  "% design LPF\n" +
  "Fe = 20*10^3; % sampling frequency\n" +
  "% cutoff frequency\n" +
  "Fp = [4000 6000];\n" +
  "Fs = [3000 8000];\n" +
  "Rp = 1;\n" +
  "Rs = 40;\n" +
  "wp = 2*pi*Fp;\n" +
  "ws = 2*pi*Fs;\n" +
  "% order\n" +
  "[n,Wn] = cheb1ord(wp,ws,Rp,Rs,'s')\n" +
  "n =4\n" +
  "Wn = 1×2\n" +
  "104 ×\n" +
  "2.5133 3.7699\n" +
  "[b, a] = cheby1(n,Rp, Wn, 's');\n" +
  "figure();\n" +
  "freqs(b,a);\n" +
  "   [bi, ai]=impinvar(b,a,Fe);\n" +
  "figure();\n" +
  "freqz(bi,ai);\n" +
  "     h1=impz(bi,ai);\n" +
  "figure();\n" +
  "stem(0:length(h1)-1,h1);\n" +
  "    figure(); zplane(bi,ai);\n" +
  "    s1=isstable(bi,ai)\n" +
  "s1 = logical 1\n" +
  "   if s1==1\n" +
  "    disp('stable')\n" +
  "else\n" +
  "    disp('unstable');\n" +
  "end\n" +
  "stable\n" +
  "wp1=2*Fe * tan(wp/Fe/2);\n" +
  "ws1=2*Fe * tan(ws/Fe/2);\n" +
  "[N1,Wn1]=cheb1ord(wp1,ws1,Rp,Rs,'s');\n" +
  "[b1,a1]= cheby1(N1,Rp,Wn1,'s');\n" +
  "[bb,ab]= bilinear (b1,a1,Fe);";
exercitii[10][3] =
  "clear variables;\n" +
  "% design LPF\n" +
  "Fe = 20*10^3; % sampling frequency\n" +
  "% cutoff frequency\n" +
  "Fp = [4000 6000];\n" +
  "Fs = [3000 8000];\n" +
  "Rp = 1;\n" +
  "Rs = 40;\n" +
  "wp = 2*pi*Fp;\n" +
  "ws = 2*pi*Fs;\n" +
  "% order\n" +
  "[n,Wn] = cheb2ord(wp,ws,Rp,Rs,'s')\n" +
  "n= 4\n" +
  "Wn = 1×2\n" +
  "104 ×\n" +
  "1.9415 4.8802\n" +
  "[b, a] = cheby2(n,Rp, Wn, 's');\n" +
  "figure();\n" +
  "freqs(b,a);\n" +
  "  [bi, ai]=impinvar(b,a,Fe);\n" +
  "figure();\n" +
  "freqz(bi,ai);\n" +
  "    h1=impz(bi,ai);\n" +
  "figure();\n" +
  "stem(0:length(h1)-1,h1);\n" +
  "    figure(); zplane(bi,ai);\n" +
  "    s1=isstable(bi,ai)\n" +
  "s1 = logical 1\n" +
  "if s1==1\n" +
  "   disp('stable')\n" +
  "else\n" +
  "    disp('unstable');\n" +
  "end\n" +
  "stable\n" +
  "wp1=2*Fe * tan(wp/Fe/2);\n" +
  "ws1=2*Fe * tan(ws/Fe/2);\n" +
  "[N1,Wn1]=cheb2ord(wp1,ws1,Rp,Rs,'s');\n" +
  "[b1,a1]= cheby2(N1,Rp,Wn1,'s');\n" +
  "[bb,ab]= bilinear (b1,a1,Fe);";
exercitii[10][4] =
  "clear variables;\n" +
  "% design LPF\n" +
  "Fe = 20*10^3; % sampling frequency\n" +
  "% cutoff frequency\n" +
  "Fp = [4000 6000];\n" +
  "Fs = [3000 8000];\n" +
  "Rp = 1;\n" +
  "Rs = 40;\n" +
  "wp = 2*pi*Fp;\n" +
  "ws = 2*pi*Fs;\n" +
  "% order\n" +
  "[n,Wn] = ellipord (wp,ws,Rp,Rs,'s')\n" +
  "n =3\n" +
  "Wn = 1×2\n" +
  "104 ×\n" +
  "2.5133 3.7699\n" +
  "[b, a] = ellip(n,Rp,Rs, Wn, 's');\n" +
  "figure();\n" +
  "freqs(b,a);\n" +
  "   [bi, ai]=impinvar(b,a,Fe);\n" +
  "figure();\n" +
  "freqz(bi,ai);\n" +
  "    h1=impz(bi,ai);\n" +
  "figure();\n" +
  "stem(0:length(h1)-1,h1);\n" +
  "    figure(); zplane(bi,ai);\n" +
  "s1=isstable(bi,ai)\n" +
  "s1 = logical 1\n" +
  "stable\n" +
  "if s1==1\n" +
  "    disp('stable')\n" +
  "else\n" +
  "    disp('unstable');\n" +
  "end\n" +
  "wp1=2*Fe * tan(wp/Fe/2);\n" +
  "ws1=2*Fe * tan(ws/Fe/2);\n" +
  "[N1,Wn1]=ellipord(wp1,ws1,Rp,Rs,'s');\n" +
  "[b1,a1]= ellip(N1,Rp,Rs,Wn1,'s');\n" +
  "[bb,ab]= bilinear (b1,a1,Fe);";
exercitii[10][5] =
  "clear variabels;\n" +
  "b=[2 0 4];\n" +
  "a=conv([1 2],[2 3 2]);\n" +
  "figure,freqs(b,a,2^10);%FTJ Analogic\n" +
  "[bz,az]=bilinear(b,a,1/0.8);\n" +
  "figure,freqz(bz,az,2^10);\n" +
  "figure ,pzmap(b,a);\n" +
  "if roots(a)<0\n" +
  "disp('Stabil!');\n" +
  "else disp('Instabil!');\n" +
  "end\n" +
  "figure ,zplane(bz,az);\n" +
  "if isstable(bz,az)==1\n" +
  "disp('Stabil!');\n" +
  "else disp('Instabil!');\n" +
  "end\n" +
  "if abs(roots(az))<1\n" +
  "disp('Stabil!');\n" +
  "else disp('Instabil!');\n" +
  "end";
exercitii[10][6] =
  "clear variabels;\n" +
  "n=5;\n" +
  "wn=0.2\n" +
  "[b,a]=butter(n,Wn,'s');\n" +
  "figure,freqs(b,a,2^10);";
exercitii[10][7] =
  "clear variabels;\n" +
  "Wp=0.22; Rp=abs(mag2db(0.95));\n" +
  "Ws=0.25; Rs=abs(mag2db(0.01));\n" +
  "[n,Wn]=buttord(Wp, Ws ,Rp, Rs, 's');\n" +
  "[b,a]=butter(n,Wn,'s');\n" +
  "figure,freqs(b,a,2^10);";
exercitii[10][8] =
  "clear variabels;\n" +
  "Wp=0.22; Rp=abs(mag2db(0.95));\n" +
  "Ws=0.25; Rs=abs(mag2db(0.01));\n" +
  "[n,Wn]=cheb2ord(Wp, Ws ,Rp, Rs, 's');\n" +
  "[b,a]=cheby2(n,Rs,Wn,'s');\n" +
  "figure,freqs(b,a,2^10);";
exercitii[10][9] =
  "clear variabels;\n" +
  "n=10;\n" +
  "f=0.22;\n" +
  "BW=0.1;\n" +
  "TB=0.02;\n" +
  "Rs=40;\n" +
  "Rp=3;\n" +
  "Wn=[f-(BW)/2-TB f+(BW/2)+TB];\n" +
  "[b,a]=cheby2(n,Rs,Wn,'stop','s');\n" +
  "figure,freqs(b,a,2^10);\n" +
  "[bz,az]=bilinear(b,a,1/3);\n" +
  "figure,freqz(bz,az,2^10);\n" +
  "h=impz(bz,az,100);\n" +
  "figure,stem(0:99,h);\n" +
  "ni=0:100;\n" +
  "x1=cos(2*pi*0.05*ni);\n" +
  "x2=cos(2*pi*0.22*ni);\n" +
  "y1=conv(h,x1);\n" +
  "y2=conv(h,x2);\n" +
  "figure,\n" +
  "subplot(411);stem(ni,x1);\n" +
  "subplot(412);stem(0:199,y1);\n" +
  "subplot(413);stem(ni,x2);\n" +
  "subplot(414);stem(0:199,y2);";
export default exercitii;

import { Route, Routes } from "react-router-dom";
import CopyClipboard from "./CopyClipboard";
function App() {
  return (
    <Routes>
      <Route path="/:lab/:ex" element={<CopyClipboard />} />
    </Routes>
  );
}

export default App;
